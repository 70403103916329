import { Fragment, useCallback, useState } from "react"

import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useDebouncedCallback } from "use-debounce"

import { IMSelectResult } from "src/ui/MSelect/MSelect"

const DEFAULT_DELAY = 500 // ms

/** @deprecated use MSelect instead */
export function AsyncDropdown<T extends IMSelectResult>({
  label,
  valueInitial,
  optionsInitial = [],
  onSelected,
  dataFetcher,
}: {
  label?: string
  valueInitial: T | undefined
  optionsInitial: T[]
  dataFetcher: (value: string) => Promise<T[]>
  onSelected: (obj: T) => void
}) {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<T[]>(optionsInitial)
  const [value, setValue] = useState<T | undefined>(valueInitial)

  const [fetching, setFetching] = useState(false) // When fetching
  const loading = fetching

  // Wrapper to prevent async functions from updating when component is unmounted
  const setter = useCallback((setCb) => {
    let active = true
    if (active) {
      setCb()
    }
    return () => {
      active = false
    }
  }, [])

  const fetch = useCallback(
    async function fetch(s: string) {
      setter(() => setFetching(true))
      const responseData = await dataFetcher(s)
      setter(() => setFetching(false))
      return responseData
    },
    [dataFetcher, setter]
  )

  const debouncedCallback = useDebouncedCallback(
    async (s: string) => {
      const response = await fetch(s)
      setter(() => setOptions(response))
    },
    DEFAULT_DELAY,
    { leading: true }
  )

  function onInputChange(newInputValue: string) {
    const newItem = options.find((o) => o.name === newInputValue)
    if (newItem) {
      setValue(newItem)
      onSelected(newItem)
    }
    if (open) {
      debouncedCallback(newInputValue)
    }
  }

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
        debouncedCallback("") // Get a default list when opening the dropdown
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name || ""}
      onInputChange={(event, newInputValue) => {
        onInputChange(newInputValue)
      }}
      value={value}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  )
}
